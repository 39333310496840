import "./App.css";
import React from "react";
import { useGoogleLogin } from "@react-oauth/google";

const BACKEND_URL = "https://backend-569133653378.asia-southeast2.run.app";
const REDIRECT_URI = "https://autoforward.brili.tech";
const REQUIRED_SCOPES = [
  "https://www.googleapis.com/auth/gmail.modify",
  "https://www.googleapis.com/auth/gmail.settings.basic",
];

function App() {
  const [loading, setLoading] = React.useState(false);
  const login = useGoogleLogin({
    onSuccess: async (codeResp) => {
      setLoading(true);
      const getTokenResp = await fetch(
        `${BACKEND_URL}/auth/login/google?code=${codeResp.code}`
      );
      if (!getTokenResp.ok) {
        alert(await getTokenResp.text());
        return;
      }
      const { tokens, message, code } = await getTokenResp.json();
      if (code === 1) {
        alert("You have been onboarded before");
        return;
      }
      if (!tokens) {
        alert(message);
        return;
      }
      const createFilterResp = await fetch(`${BACKEND_URL}/api/filters/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${tokens.access_token}`,
        },
      });
      if (createFilterResp.ok) {
        alert("Gmail integrated successfully");
      }
      setLoading(false);
    },
    flow: "auth-code",
    scope: REQUIRED_SCOPES.join(" "),
    redirect_uri: REDIRECT_URI,
  });
  return (
    <div className="App">
      <header className="App-header">
        <h2>Getting Started</h2>
        <div
          style={{
            margin: "11px",
          }}
        >
          <p>Integrate with your google account to get started.</p>
          <p>
            By giving us permissions to create email filters & manage your
            inbox, we will be able to label trade confirmation emails you
            receive from your broker platform & send them to us for further
            analysis, mainly to provide you with accurate trade signals.
          </p>
          <p>By clicking the button below, you consent to these permissions.</p>
          <button
            onClick={login}
            style={{ marginTop: "10px", borderRadius: "3px" }}
            disabled={loading}
          >
            Integrate Gmail
          </button>
        </div>
      </header>
    </div>
  );
}

export default App;
